console.log('ayo')

document.querySelector('#menu-btn').addEventListener("click", menuToggle);
document.querySelectorAll("#menu a").forEach((item) => item.addEventListener("click", menuToggle));

function menuToggle() {
  document.body.toggleAttribute('is-opened')
}

// Viewport Observer
// attaches intersection-observer to every [data-inviewport] element

function viewportObserver(containerQuery) {
    const inViewport = (entries) => {
        entries.forEach((entry) => {
            entry.target.classList.toggle("is-inViewport", entry.isIntersecting);
        });
    };

    const obsOptions = {
        // threshold: 0.5,
        rootMargin: "-108px",
    };

    const Obs = new IntersectionObserver(inViewport, obsOptions);

    document.querySelectorAll(containerQuery).forEach((el) => {
        el.classList.toggle("inViewport-init");
        Obs.observe(el);
    });
}

export default viewportObserver

viewportObserver("[data-inviewport]");
